import React, { useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import Button from 'react-bootstrap/Button';
import { AddProduct } from '../component/addproduct';
import { HomeSlider } from '../slider';

const FlipkartAffidGenerator = () => {
  const [inputUrl, setInputUrl] = useState('');
  const [generatedUrl, setGeneratedUrl] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState('Copy to Clipboard');

  const handleGenerateUrl = () => {
    // Extract the pid value from the input URL
    const match = inputUrl.match(/pid=([^&]+)/);
    const pid = match ? match[1] : null;

    // Set the generated URL with the affid parameter
    if (pid) {
      const newUrl = `${inputUrl}&affid=gspremkum&affExtParam1=prem&affExtParam2=7689`;
      setGeneratedUrl(newUrl);

      // Clear the input value
      setInputUrl('');
    } else {
      setGeneratedUrl('');

      // Show the toast with an error message
      setToastText('Error: Invalid URL. Please enter a valid Flipkart product URL.');
      setShowToast(true);
    }
  };

  const handleCopyToClipboard = () => {
    if (generatedUrl) {
      navigator.clipboard.writeText(generatedUrl)
        .then(() => {
          setToastText('Copied!');
          setShowToast(true);
        })
        .catch((err) => {
          console.error('Error copying to clipboard:', err);
        });
    } else {
      setToastText('Error: Nothing to copy. Generate a valid URL first.');
      setShowToast(true);
    }
  };

  return (
    <div className='container-fluid '>

    
    <div className='w-75 mx-auto cbl-generator'>
      <h1 className='text-center mb-5' >'Link it, Click it, Cash it'</h1>
      <label className='d-block'>Paste Flipkart Product URL:</label>
      <div className='input-group mb-3'>
        <input
          className='d-block form-control rounded-0 form-control'
          type="text"
          value={inputUrl}
          onChange={(e) => setInputUrl(e.target.value)}
          placeholder='Paste any Flipkart.com product URL here to Generate cashback Link'
        />
        <button onClick={handleGenerateUrl} className='btn btn-primary rounded-0'>Generate URL</button>
      </div>
      <div className='mb-3'>
        <label>Cashback URL:</label>
        <div className='input-group'>
          <input
            className='form-control rounded-0'
            type='text'
            value={generatedUrl}
            readOnly
          />
          <Button
            onClick={handleCopyToClipboard}
            className='btn btn-secondary rounded-0'
            disabled={!generatedUrl}
          >
            Copy to Clipboard
          </Button>
        </div>
      </div>
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        <Toast.Body>{toastText}</Toast.Body>
      </Toast>
    </div>
    <HomeSlider></HomeSlider>
    <AddProduct></AddProduct>
    </div>
  );
};

export default FlipkartAffidGenerator;
