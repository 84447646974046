import './App.css';
// import { Home } from './home';
import { BrowserRouter,Route, Routes } from 'react-router-dom';
import { TopNav } from './topnav';
import { AddProduct } from './component/addproduct';
// import { ProductForm } from './component/productForm';
import  ProductDetail  from './component/productDetail';
import CategoryPage from './component/productCategory';
// import { ProductCard } from './component/productCard';
import FlipkartAffidGenerator from './home';
function App() {
  return (
    <BrowserRouter>

    <TopNav></TopNav>

    <Routes>
      <Route path="" element={<FlipkartAffidGenerator/>}/>
      <Route path="addproduct" element={<AddProduct/>}/>
      {/* <Route path="add" element={ <ProductForm/>}/> */}
      <Route path="/product/:id" element={ <ProductDetail/>}/>
      <Route path="/:category" element={<CategoryPage/>} />
    </Routes>
    </BrowserRouter>
  );
}

export default App;
