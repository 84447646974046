// ProductDetail.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import Slider from "react-slick";
// import { baseUrl } from "./config";

const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const baseUrl = '';
  const settings = {
    customPaging: function(i) {
      return (
        <a>
          <img src={`${baseUrl}${product.images[i]}`} className="img-fluid"/>
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:false
  };
  useEffect(() => {
    // Fetch product details based on the id
    // You can use your API or local data source here
    // For demonstration purposes, let's assume you have a function fetchProductById
    const fetchProductById = async (productId) => {
        try {
          const response = await fetch(`https://api.escuelajs.co/api/v1/products/${productId}`);
          if (!response.ok) {
            throw new Error(`Failed to fetch product. Status: ${response.status}`);
          }
      
          const productData = await response.json();
          setProduct(productData);
        } catch (error) {
          console.error("Error fetching product:", error.message);
        }
      };

    fetchProductById(id);
  }, [id]);

  if (!product) {
    return  <Spinner animation="border" role="status">
    <span className="visually-hidden">Loading...</span>
  </Spinner>
  }
console.log(product)
  return (
    <div className="container mt-5 single-product-slider">
        <div className="row">
            <div className="col-md-5 col-12">
            <Slider {...settings}>
          <div>
            <img src={baseUrl + product.images[0]} className="img-fluid" />
          </div>
          <div>
            <img src={baseUrl + product.images[1]} className="img-fluid" />
          </div>
          <div>
            <img src={baseUrl + product.images[2]} className="img-fluid" />
          </div>
        </Slider>
            {/* <img src={product.images[0]} alt={product.title} className="img-fluid"/> */}
            </div>
            <div className="col-md-7 col-12">
            <h2>{product.title}{product.title}{product.title}</h2>
      {/* Render other details as needed */}
      <div className="productPrice justify-content-start mt-3">
            <span className="offerPrice">&#8377;{product.price}</span>
            <span className="discount">5% off</span>
          </div>
      <h2 className="mt-3">Product Description:</h2>
      <p> {product.description}
      {product.description}
      {product.description}
      {product.description}</p>
            </div>
        </div>
     
      {/* Map through the array of images and render each image */}
      {/* {product.images?.map((imageUrl, index) => (
        <img key={index} src={imageUrl} alt={`Product ${index + 1}`} />
      ))} */}
      
    </div>
  );
};

export default ProductDetail;
