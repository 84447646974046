import { React} from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
export function HomeSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows:false,
    pauseOnHover: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ],
      
  };
  const imageUrls = [
    {
        url:'https://cashkaro.com/ajio-flat-60-off-on-jeans-flat-4-cashkaro-cashback/PPS17-ajio-dc-dod-jeans-311023',
        img:'https://asset22.ckassets.com/resources/image/staticpage_images/Ajio-DC-TSP(1)-31-10-1698748593.png'
    },
    {
        url:'https://cashkaro.com/ajio-flat-60-off-on-jeans-flat-4-cashkaro-cashback/PPS17-ajio-dc-dod-jeans-311023',
        img:'https://asset22.ckassets.com/resources/image/staticpage_images/Ajio-DC-TSP(2)-31-10-1698748608.png'
    },
    {
        url:'https://cashkaro.com/ajio-flat-60-off-on-jeans-flat-4-cashkaro-cashback/PPS17-ajio-dc-dod-jeans-311023',
        img:'https://asset22.ckassets.com/resources/image/staticpage_images/Ajio-DC-TSP(3)-31-10-1698748625.png'
    },
    {
        url:'https://cashkaro.com/ajio-flat-60-off-on-jeans-flat-4-cashkaro-cashback/PPS17-ajio-dc-dod-jeans-311023',
        img:'https://asset22.ckassets.com/resources/image/staticpage_images/Ajio-DC-TSP(5)-31-10-1698748737.png'
    },
    {
        url:'https://cashkaro.com/ajio-flat-60-off-on-jeans-flat-4-cashkaro-cashback/PPS17-ajio-dc-dod-jeans-311023',
        img:'https://asset22.ckassets.com/resources/image/staticpage_images/Ajio-DC-TSP(1)-31-10-1698748593.png'
    },
    {
        url:'https://cashkaro.com/ajio-flat-60-off-on-jeans-flat-4-cashkaro-cashback/PPS17-ajio-dc-dod-jeans-311023',
        img:'https://asset22.ckassets.com/resources/image/staticpage_images/Ajio-DC-TSP(1)-31-10-1698748593.png'
    },
    {
        url:'https://cashkaro.com/ajio-flat-60-off-on-jeans-flat-4-cashkaro-cashback/PPS17-ajio-dc-dod-jeans-311023',
        img:'https://asset22.ckassets.com/resources/image/staticpage_images/Ajio-DC-TSP(2)-31-10-1698748608.png'
    },
    {
        url:'https://cashkaro.com/ajio-flat-60-off-on-jeans-flat-4-cashkaro-cashback/PPS17-ajio-dc-dod-jeans-311023',
        img:'https://asset22.ckassets.com/resources/image/staticpage_images/Ajio-DC-TSP(3)-31-10-1698748625.png'
    },
    {
        url:'https://cashkaro.com/ajio-flat-60-off-on-jeans-flat-4-cashkaro-cashback/PPS17-ajio-dc-dod-jeans-311023',
        img:'https://asset22.ckassets.com/resources/image/staticpage_images/Ajio-DC-TSP(5)-31-10-1698748737.png'
    },
    {
        url:'https://cashkaro.com/ajio-flat-60-off-on-jeans-flat-4-cashkaro-cashback/PPS17-ajio-dc-dod-jeans-311023',
        img:'https://asset22.ckassets.com/resources/image/staticpage_images/Ajio-DC-TSP(1)-31-10-1698748593.png'
    }
  ]
  return (
    <div>
<div className="container-fluid mt-4">
    <h2 className="text-start ps-2">AJIO - ALL STARS SALE</h2>
    <Slider {...settings} className="mb-5">
    {imageUrls.map((data,i)=>
    <div key={i}>
        <a href={data.url} target="blank">
        <img src={data.img} alt={`img-${i + 1}`} className="img-fluid"/>
        </a>
    </div>
    )}
      
    
    </Slider>
  </div>

    </div>
    
  );
}
