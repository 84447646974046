import React from "react";
import { Link } from "react-router-dom";

export const ProductCard = ({ productData }) => {
  const { id, title, image, mrp, price } = productData;

  return (
    <Link to={`/product/${id}`} className="productCard" target="_blank" rel="noreferrer">
      <div>
        <div className="product-img">
          <img src={image[0]} alt="Product" />
        </div>
        <div className="productDetail">
          <div className="productName text-center">
            {title}
            {title}
          </div>
          <div className="productPrice">
            <span className="mrp">&#8377;{mrp}</span>
            <span className="offerPrice">&#8377;{price}</span>
            <span className="discount">5% off</span>
          </div>
        </div>
      </div>
    </Link>
  );
};


