// CategoryPage.js
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import { ProductCard } from "../productCard";

const CategoryPage = () => {
  const { category } = useParams();
  const [categoryProducts, setCategoryProducts] = useState([]);

  useEffect(() => {
    // Fetch data for the specific category
    axios.get(`https://api.escuelajs.co/api/v1/products?category=${category}`)
      .then((response) => {
        const apiData = response.data;
        const formattedData = apiData.map(item => ({
          id: item.id,
          title: item.title,
          productURL: item.link,
          image: item.images,
          mrp: Math.round(item.price),
          price: Math.round(item.price),
          category: item.category.name,
        }));
        setCategoryProducts(formattedData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [category]);
console.log(categoryProducts,category);
  return (
    <div>
      <Link to="/">Go back to Home</Link>

      <h2>{category}</h2>
      <div className="container-fluid">
      <div className="row g-3">
  {categoryProducts
    .filter(product => product.category === category) // Filter products based on category
    .map((product, index) => (
      <div key={index} className="col-md-2 col-6">
        <ProductCard productData={product} />
      </div>
    ))}
</div>
      </div>
    </div>
  );
};

export default CategoryPage;
