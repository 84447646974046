import { React, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FaBars } from "react-icons/fa6";
import {  Link } from "react-router-dom";
export function TopNav() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleLinkClick = () => {
    // Close the Offcanvas when a link is clicked
    handleClose();
  };
  return (
    <div>
    
    <Navbar expand="lg" className="nav-paisastore">
      <Container fluid >
       <div className="d-flex">
       <Button variant="Link" onClick={handleShow}>
          <FaBars />
        </Button>

        <Link Link to="" className="navbar-brand">PaisaStore</Link>
        <Form className="d-flex">
          <Form.Control
            type="search"
            placeholder="Search for products, brands, stores and more"
            className="me-2 rounded-0 shadow"
            aria-label="Search"
          />
        </Form>
       </div>
       <div>
       {/* <Navbar.Toggle aria-controls="navbarScroll" /> */}

{/* <Navbar.Collapse id="navbarScroll"> */}
  {/* <Nav
    className="me-auto my-2 my-lg-0"
    style={{ maxHeight: "100px" }}
    navbarScroll
  > */}
    <div className="d-flex justify-content-between">
      <div>
        <Link to="add">addProduct</Link>
      </div>
    </div>
  {/* </Nav> */}
{/* </Navbar.Collapse> */}
       </div>
      </Container>
      <Offcanvas show={show} onHide={handleClose} >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Categories</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="sidemenu">
            <li><Link onClick={handleLinkClick} to="/Electronics">Mobiles</Link></li>
            <li><Link onClick={handleLinkClick} to="/Electronics">Laptops</Link></li>
            <li><Link onClick={handleLinkClick} to="/Electronics">Headphones</Link></li>
            <li><Link onClick={handleLinkClick} to="/Clothes">Mens Fashion</Link></li>
            <li><Link onClick={handleLinkClick} to="/Clothes">Womens Fashion</Link></li>
            <li><Link onClick={handleLinkClick} to="/Clothes">Kids Fashion</Link></li>
            <li><Link onClick={handleLinkClick} to="/Shoes">Health and Wellness</Link></li>
            <li><Link onClick={handleLinkClick} to="/Furniture">Home and Kitchen</Link></li>
            <li><Link onClick={handleLinkClick} to="/Furniture">Appliances</Link></li>
            <li><Link onClick={handleLinkClick} to="/Shoes">Beauty Products</Link></li>
            <li><Link onClick={handleLinkClick} to="/Shoes">Travel</Link></li>
            <li><Link onClick={handleLinkClick} to="/Shoes">Food</Link></li>
            <li><Link onClick={handleLinkClick} to="/Shoes">Groceries</Link></li>

          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </Navbar>
   
    </div>
  );
}
