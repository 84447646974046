import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import { ProductCard } from "../productCard";

export function AddProduct() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ]
  };

  const [productList, setProductList] = useState([]);

  useEffect(() => {
    axios.get('https://api.escuelajs.co/api/v1/products')
      .then((response) => {
        const apiData = response.data;
        const formattedData = apiData.map(item => ({
          id: item.id,
          title: item.title,
          productURL: item.link,
          image: item.images,
          mrp: Math.round(item.price),
          price: Math.round(item.price),
          category: item.category.name,
        }));
        setProductList(formattedData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const groupedProducts = productList.reduce((grouped, product) => {
    const category = product.category;
    if (!grouped[category]) {
      grouped[category] = [];
    }
    grouped[category].push(product);
    return grouped;
  }, {});

  return (
    <div className="container-fluid">
      {Object.keys(groupedProducts).map((category, index) => (
        <div key={index} className="mt-5">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="text-start ps-2 text-capitalize category-title">{category}</h2>
            <Link to={`/${encodeURIComponent(category)}`} className="view-all">View All</Link>
          </div>
          <Slider {...settings}>
            {groupedProducts[category].map((product, productIndex) => (
              <div key={productIndex}>
                <ProductCard productData={product} />
              </div>
            ))}
          </Slider>
        </div>
      ))}
    </div>
  );
}
